"use client"

import { ReactNode } from "react"
import { ArrowRightIcon } from "src/modules/common/icons"
import { LocalizedClientLink } from "./LocalizedClientLink"

export type InteractiveLinkProps = {
  href: string
  children?: ReactNode
  onClick?: () => void
}

export const InteractiveLink = ({ href, children, onClick, ...props }: InteractiveLinkProps) => {
  return (
    <LocalizedClientLink
      className="gap-x-1 group text-interactive flex items-center text-base font-medium"
      href={href}
      onClick={onClick}
      {...props}
    >
      {children}
      <ArrowRightIcon size={16} className="group-hover:rotate-0 duration-150 ease-in-out -rotate-45" />
    </LocalizedClientLink>
  )
}
