import { remember } from "@epic-web/remember"
import { getCountryCodeFromUrl, getLocaleFromUrl } from "src/localization/client"
import { AppLocale, DEFAULT_LOCALE, getLocaleForCountryCode } from "src/localization/localization"
import { isBrowser } from "src/utilities/env"

export type LocalizationState = {
  countryCode: string | undefined
  locale: AppLocale | undefined
}

// Using "remember" is necessary to preserve state across SSR render contexts.
const state = remember<LocalizationState>("localization", () => ({
  countryCode: undefined,
  locale: undefined,
}))

export function getCountryCodeOrThrow() {
  if (!state.countryCode && isBrowser()) {
    state.countryCode = getCountryCodeFromUrl()
  }

  if (state.countryCode) {
    return state.countryCode
  }

  throw new Error("Country code is not set.")
}

export function getLocaleOrThrow() {
  if (!state.locale && isBrowser()) {
    state.locale = getLocaleFromUrl()
    console.log("LOCALE = " + state.locale)
  }

  if (state.locale) {
    return state.locale
  }

  throw new Error("Locale is not set.")
}

export function setLocalizationStateForTesting(newState: LocalizationState) {
  if (process.env.NODE_ENV !== "test") {
    throw new Error("This function is only for testing.")
  }

  state.locale = newState.locale
  state.countryCode = newState.countryCode
}

/**
 * On the server, the only way to know the locale is to look at the params in
 * the page props. But the page props are only available to the top-level page
 * component. To make it available to all components without passing it down to
 * each one, we save it here.
 */
export function deriveStateFromRouteProps(props: { params?: { countryCode?: string | undefined } }) {
  state.countryCode = props.params?.countryCode || DEFAULT_LOCALE
  state.locale = getLocaleForCountryCode(state.countryCode)

  if (!state.locale) {
    throw new Error("Failed to determine locale for country code: " + state.countryCode)
  }
}
