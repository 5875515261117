import { captureException } from "@sentry/nextjs"

export function reportError(message: string, cause?: unknown) {
  let combinedMessage = message
  let lastError = cause

  while (lastError instanceof Error && lastError.message) {
    combinedMessage += `: ${lastError.message}`
    lastError = lastError.cause
  }

  console.error(combinedMessage)
  captureException(new Error(combinedMessage))
}
