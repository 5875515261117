import { DEFAULT_COUNTRY_CODE } from "src/localization/localization"
import { getCountryCodeOrThrow } from "src/localization/state"

export const routes = {
  // product pages
  home: "/",
  format_collection: (handle: string) => `/kollektionen/${handle}` as const,
  format_category: (handle: string) => `/kategorien/${handle}` as const,
  products: "/alle-boxen",
  format_product: (handle: string | null) => (handle ? (`/box/${handle}` as const) : ("#" as const)),
  search: "/suche",
  searchResults: "/ergebnisse",

  // checkout pages
  cart: "/warenkorb",
  checkout: "/kasse",
  paymentFailed: "/kasse/fehlgeschlagen",
  format_checkoutStep: (step: string) => `/kasse?step=${step}` as const,
  thankYouRoot: "/danke",
  format_thankYou: (cartOrOrderId: string) => `/danke/${cartOrOrderId}` as const,

  // account pages
  accountRoot: "/account",
  accountAddresses: "/account/adressen",
  accountOrders: "/account/bestellungen",
  format_accountOrder: (orderId: string) => `/account/bestellungen/${orderId}` as const,
  accountProfile: "/account/profil",

  // about pages
  contact: "/kontakt",
  aboutUs: "/ueber-uns",
  career: "/karriere",

  // legal pages
  cookies: "/cookies",
  legalInfo: "/impressum",
  payment: "/zahlung",
  privacy: "/datenschutz",
  revocation: "/widerruf",
  shipping: "/versand",
  terms: "/agb",
} as const

export function localizePath(path: string) {
  const countryCode = getCountryCodeOrThrow()
  const localizedPath = countryCode === DEFAULT_COUNTRY_CODE ? path : `/${countryCode}${path}`

  return localizedPath
}
