import { routes } from "src/config/routes"
import { localizeText } from "src/localization/utils"
import { InteractiveLink } from "src/modules/common/components/InteractiveLink"

export type ErrorFeedbackProps = {
  title?: string
  description?: string
  backHref?: string
  backLabel?: string
}

export function ErrorFeedback({ title, description, backHref, backLabel }: ErrorFeedbackProps) {
  const T = localizeText(text)

  return (
    <div className="flex flex-col gap-4 justify-center min-h-[50vh] max-w-md mx-auto text-center items-center">
      <h1 className="text-h2-serif">{title || T.title}</h1>
      <p>{description || T.description}</p>
      <InteractiveLink href={backHref || routes.home}>{backLabel || T.goHome}</InteractiveLink>
    </div>
  )
}

const de = {
  title: "Fehler",
  description: "Es tut uns leid, ein Fehler ist aufgetreten.",
  goHome: "Zur Startseite",
}

const en: typeof de = {
  title: "Error",
  description: "Sorry, an error occurred.",
  goHome: "Go to frontpage",
}

const text = { de, en }
