import { isBrowser } from "src/utilities/env"
import { DEFAULT_COUNTRY_CODE, getLocaleForCountryCode } from "./localization"

function extractCountryCodeFromPathname(pathname: string) {
  const countryCodeSegment = pathname.split("/")[1]

  if (countryCodeSegment?.length === 2) {
    return countryCodeSegment
  }

  return DEFAULT_COUNTRY_CODE
}

export function getCountryCodeFromUrl() {
  if (!isBrowser()) {
    throw new Error("getCountryCodeFromUrl can only be called in the browser")
  }

  const pathname = window.location.pathname
  const countryCode = extractCountryCodeFromPathname(pathname)

  return countryCode
}

export function getLocaleFromUrl() {
  if (!isBrowser()) {
    throw new Error("getLocaleFromUrl can only be called in the browser")
  }

  const countryCode = getCountryCodeFromUrl()

  return getLocaleForCountryCode(countryCode)
}
